<template>
  <article class="partner-page">
    <h1 class="sr-only">Electrify America Volvo Page</h1>
    <Hero
      :fullwidth="true"
      heading="Power the full potential of your Volvo Recharge"
      headlineLevel="h2"
      subheading="Conquer the roads ahead and enjoy the many benefits offered by Volvo and the nation’s largest DC Ultra-Fast charging network. "
    >
      <template slot="image">
        <picture>
          <source media="(min-width: 750px)" srcset="@/assets/images/Partners/Volvo/volvo1.png" />
          <img src="@/assets/images/Partners/Volvo/volvo1-mobile.png" alt="" />
        </picture>
      </template>
    </Hero>

    <TwoColumnContentBlock heading="Evolve with Electrify America" id="select-model" headlineLevel="h3">
      <p>
        The future of electric mobility can’t wait, and with Electrify America, it doesn’t have to. We’ve built a
        convenient, reliable, and growing network of over 4,250 electric vehicle (EV) chargers coast-to-coast–along
        major highways and routes to take EV drivers farther than ever before.
      </p>
      <PartnerDropdown id="volvo-select" v-model="VolvoYearChosen" :options="VolvoDropdownOptions">
        <template slot="label">
          <div id="partner-label-container">
            <label for="volvo-select"> Select your Volvo Recharge model</label> &nbsp;
            <ReferenceLink id="partner-reference-link" :num="1" v-if="VolvoYearChosen === null">
              Available on eligible new model year Volvo Recharge models. Charging plan begins upon vehicle purchase.
              Complimentary charging time does not include any idle fees and their associated or applicable taxes
              assessed at the charging station. You will be responsible for paying such fees through your account if you
              incur them during a charging session. There is a 60-minute waiting period between eligible charging
              sessions, and the promotion benefits will not apply to a charging session initiated within this period.
              Non-transferable. Not available for commercial use such as ridesharing. Requires Electrify America
              account, app, enrollment in eligible charging plan, and acceptance of Terms of Use. After user-initiated
              charge session stop or vehicle reaches full charge or charging otherwise stops, and 10-minute grace
              period, idle fees and applicable taxes will apply, and user is responsible.</ReferenceLink
            >
          </div>
        </template>
      </PartnerDropdown>
      <p v-if="VolvoYearChosen === null">
        Volvo Recharge drivers can explore the full potential of electric freedom, leaving gas stations and MPG in the
        rear-view mirror.
      </p>
      <section v-else>
        <p>
          {{ `${VolvoYearChosen} models `
          }}<reference-link :num="1"
            >Available on new <span>{{ `${referenceLinkModel}` }}</span> Recharge vehicles purchased from an authorized
            Volvo Dealer. Offer commences upon vehicle purchase, and includes 250 kilowatt-hours of DC fast charging
            over three years followed by a subsequent 12 month Pass+ Membership. 12 Month Pass+ Membership following 250
            kilowatt-hours of DC fast charging does not include cost of charging sessions. Non-transferable. Not
            available for commercial use, such as ridesharing. Requires Electrify America account, app, acceptance of
            Terms of Use and Privacy Policy, and enrollment in Volvo DC Fast Charging plan. After user-initiated charge
            session stop or vehicle reaches full charge or charging otherwise stops, and 10-min grace period, Idle Fees
            will be applied and user is responsible. In the event of suspected fraud or abuse or other unforeseen
            events, Electrify America may discontinue or modify the offer in its sole discretion.
          </reference-link>
          come with 250 kWh of complimentary charging available for 3 years after date of vehicle purchase and
          subsequent 12-month Electrify America Pass+ membership.<reference-link :num="2"
            >Charging sessions under Pass+ membership are not included in this promotion.</reference-link
          >
          For full program details,
          <span class="wrap-last-two-words"
            >click <router-link class="link" :to="{ name: chargingPlanLink }">here</router-link>.</span
          >
        </p>
        <p>
          {{
            `${VolvoYearChosen} drivers can explore the full potential of electric freedom, leaving gas stations
          and MPG in the rear-view mirror.`
          }}
        </p>
      </section>

      <router-link class="button" to="/locate-charger">Locate a Charger</router-link>
    </TwoColumnContentBlock>

    <TwoColumnContentBlock heading="Go with freedom" headlineLevel="h3" fullwidth>
      <template #image>
        <picture
          ><source media="(min-width: 750px)" srcset="@/assets/images/Partners/Volvo/volvo2.png" />
          <img loading="lazy" src="@/assets/images/Partners/Volvo/volvo2-mobile.png" alt="" />
        </picture>
      </template>
      <p v-if="VolvoYearChosen === null">
        Get going with Electrify America’s DC Ultra-Fast charging network. Select your Volvo model above to learn about
        available benefits.
      </p>
      <p v-else>
        Get going with 250 kWh complimentary charging and subsequent 12-month Electrify America Pass+ membership. Just
        follow these simple steps:
      </p>
      <ul v-if="VolvoYearChosen !== null">
        <li>
          <p>
            <strong>Step 1:</strong> Download the Electrify America app,
            <reference-link :num="3"
              >Your mobile carrier’s messaging, data, and other rates and fees will apply to your use of the Electrify
              America mobile application.
            </reference-link>
            create your account, and select "Set Up Public Charging."
          </p>

          <p>
            <strong
              >Existing Electrify America members may select "Premium Offers" in the Account tab, then "I have an
              enrollment code".</strong
            >
          </p>
        </li>
        <li>
          <strong>Step 2:</strong> Select "Choose Membership Plan" then "Premium Offers."
          <ul>
            <li>Or search for Premium Offer by vehicle information.</li>
            <li>
              Existing Electrify America members may select “Premium Offers” in the Account tab, then “I have an
              enrollment code.”
            </li>
          </ul>
        </li>
        <li>
          <strong>Step 3:</strong> Input your 9-digit enrollment code, which you will receive from your Volvo dealer.
          Then select {{ `“${chargingPlan}”` }}. Input your vehicle’s 17-digit
          <span class="wrap-last-two-words"
            >VIN
            <reference-link :num="4"
              >The VIN can usually be found on the driver’s side dashboard, near or under the bottom portion of the
              windshield.
            </reference-link></span
          >
          for verification.
        </li>
        <li><strong>Step 4:</strong> Accept Plan Disclosure, then tap “Select this plan.”</li>
        <li>
          <strong>Step 5:</strong> Enter payment info and set auto-reload.
          <ul>
            <li>
              You will need to enter payment method
              <reference-link :num="5"
                >To help ensure you can charge uninterrupted, your Electrify America account balance automatically
                reloads when it falls below $5. You’ll be billed the auto-reload amount you select in the app, the first
                time you charge at an Electrify America station, and the billed amount will be held in your account to
                cover costs not covered by this plan, such as idle fees and applicable taxes.</reference-link
              >
              to cover any charging outside of complimentary charging offer incurred idle fees
              <reference-link :num="6"
                >Drivers who do not unplug and move their vehicle ten minutes after their charging session is complete
                will be charged a $0.40 per-minute idle fee. This fee encourages drivers to move their car promptly so
                that others can use the charger. You can be notified when your charging session is complete by text
                message (your mobile carrier’s messaging, data and other rates may apply) or see it on the charger
                screen.</reference-link
              >
              or applicable taxes.
            </li>
          </ul>
        </li>
      </ul>
      <p v-if="VolvoYearChosen !== null">
        Offer enrollment can be as smooth as your journeys, but if you encounter any roadblocks, call 1-833-632-2778 to
        contact Electrify America Customer Assistance, available 24/7.
      </p>
      <router-link class="button" v-if="VolvoYearChosen !== null" to="/mobile-app">DOWNLOAD THE APP</router-link>
      <a v-else class="button" href="#select-model">SELECT YOUR MODEL</a>
    </TwoColumnContentBlock>
  </article>
</template>

<script>
import Hero from '@/components/Hero/Hero.vue';
import ReferenceLink from '@/components/ReferenceLink/ReferenceLink.vue';
import TwoColumnContentBlock from '@/components/TwoColumnContentBlock/TwoColumnContentBlock.vue';
import PartnerDropdown from '@/components/PartnerDropdown/PartnerDropdown.vue';

export default {
  name: 'Volvo',
  components: { ReferenceLink, Hero, TwoColumnContentBlock, PartnerDropdown },
  metaInfo: {
    title: 'Volvo DC Fast Charging  | Electrify America',
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/volvo/' }],
  },
  data() {
    return {
      VolvoDropdownOptions: [
        { text: 'Select...', value: null },
        { text: '2023 Volvo XC40 Recharge', value: '2023 Volvo XC40 Recharge' },
        { text: '2023 Volvo C40 Recharge', value: '2023 Volvo C40 Recharge' },
        { text: '2022 Volvo C40 Recharge and XC40 Recharge', value: '2022 Volvo C40 Recharge and XC40 Recharge' },
      ],
      VolvoYearChosen: null,
    };
  },
  computed: {
    chargingPlan() {
      if (JSON.stringify(this.VolvoYearChosen).includes('2022')) {
        return 'Volvo DC Fast Charging Plan';
      }
      if (JSON.stringify(this.VolvoYearChosen).includes(' C40 ')) {
        return '2023 Volvo C40 Recharge Plan';
      }
      if (JSON.stringify(this.VolvoYearChosen).includes('XC40')) {
        return '2023 Volvo XC40 Recharge Plan';
      }
      return '';
    },
    chargingPlanLink() {
      if (JSON.stringify(this.VolvoYearChosen).includes('2022')) {
        return 'volvo-dc-fast-charging-disclosure-en';
      }
      if (JSON.stringify(this.VolvoYearChosen).includes(' C40 ')) {
        return '2023-volvo-c40-recharge-plan-disclosure-en';
      }
      if (JSON.stringify(this.VolvoYearChosen).includes('XC40')) {
        return '2023-volvo-xc40-recharge-plan-disclosure-en';
      }
      return '';
    },
    referenceLinkModel() {
      if (JSON.stringify(this.VolvoYearChosen).includes('2022')) {
        return 'MY22 Volvo XC40 Recharge and C40';
      }
      if (JSON.stringify(this.VolvoYearChosen).includes(' C40 ')) {
        return 'MY23 Volvo C40';
      }
      if (JSON.stringify(this.VolvoYearChosen).includes('XC40')) {
        return 'MY23 Volvo XC40';
      }
      return '';
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .hero .hero__image {
  height: auto;
  img {
    object-position: bottom;
    max-height: none;
  }
}
</style>
